import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { Box } from "@components/box";
import { FaqList } from "@components/faq";
import { FaqSearch } from "@components/faq-search";
import { PageLayout, SEO } from "@components/page-layout";
import { GrayPhrase, PageHeading, Paragraph } from "@components/typography";

import { FaqLinksType } from "@components/faq/types";

const getListFromName = (slug: string, data: any) => {
  let list: { slug: string; question: string }[] = [];

  data.nodes.forEach((node: any) => {
    if (node.category) {
      if (node.category.slug === slug)
        list.push({
          slug: `/faq/${node.category.slug}/${node.slug}`,
          question: node.question,
        });
    }
  });

  return list as FaqLinksType[];
};

const Faq = () => {
  const { allContentfulFaqCategory, allContentfulFaqQuestion } =
    useStaticQuery(graphql`
      {
        allContentfulFaqCategory(sort: { fields: orderNumber, order: ASC }) {
          nodes {
            name
            slug
            metaTitle
            metaDescription {
              metaDescription
            }
          }
        }

        allContentfulFaqQuestion {
          nodes {
            name
            slug
            question
            category {
              name
              slug
            }
          }
        }
      }
    `);

  return (
    <PageLayout>
      <SEO title="FAQ" />
      <Box className="text-center max-w-[660px] md:max-w-full mx-auto mb-[66px] mt-[8.25rem] md:mt-[4rem] sm:px-[22px]">
        <PageHeading className="!w-full mb-[22px]">FAQ</PageHeading>
        <Paragraph>
          <GrayPhrase>
            Curabitur quam erat, sollicitudin et congue quis, elementum eu
            sapien. Pellentesque ut massa nec nunc sagittis condimentum eu at
            arcu.
          </GrayPhrase>
        </Paragraph>
      </Box>
      <Box
        display="flex"
        className="mx-auto max-w-[1320px] md:max-w-full flex-col justify-between lg:px-[22px] md:flex-wrap md:justify-evenly"
      >
        <FaqSearch />
        <Box display="grid" className="w-full grid-cols-3 sm:grid-cols-1">
          {allContentfulFaqCategory.nodes.map((node: any, ndx: number) => (
            <FaqList
              key={ndx}
              categoryName={node.name}
              categorySlug={node.slug}
              list={getListFromName(node.slug, allContentfulFaqQuestion)}
            />
          ))}
        </Box>
      </Box>
    </PageLayout>
  );
};

export default Faq;
